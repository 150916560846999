<template>
  <div v-if="Object.keys(profileData).length" id="user-profile">
    <profile-header :header-data="userData" />
    <!-- profile info  -->
    <!-- form -->
    <b-form>
      <b-row>
        <b-col lg="3" cols="12" order="2" order-lg="1">
          <ProfileAbout
            :about-data="{
              about: 'N/A',
              joined: moment(userData.created_at).format(
                'DD MMM YYYY HH:mm:ss'
              ),
              email: userData.email,
              whatsapp: userData.phone
            }"
          />
        </b-col>
        <!-- phone -->
        <b-col lg="6" cols="12" order="1" order-lg="2" v-if="userData">
          <b-row>
            <b-col cols="12">
              <!-- email -->
              <b-form-group
                label="Email Baru ( Kosongkan jika tidak ingin mengubah email )"
                label-for="register-email"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Email"
                  rules="email"
                >
                  <b-form-input
                    id="register-email"
                    v-model="form.email"
                    name="register-email"
                    :state="errors.length > 0 ? false : null"
                    placeholder="star@sal.com"
                    autocomplete="off"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- Phone -->
              <b-form-group
                label="Nomor Whatsapp * ( Ex: 8155 7777 33 )"
                label-for="phone"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Nomor Whatsapp *"
                  vid="phone"
                  :rules="{
                    required: true
                  }"
                >
                  <b-input-group>
                    <b-input-group-prepend is-text>
                      ID (+62)
                    </b-input-group-prepend>
                    <cleave
                      id="phone"
                      v-model="form.phone"
                      class="form-control"
                      :raw="false"
                      :options="options.phone"
                      placeholder="8155 7777 33"
                      :state="errors.length > 0 ? false : null"
                    />
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- password -->
              <b-form-group
                label-for="register-password"
                label="Password Baru ( Kosongkan jika tidak ingin mengubah password )"
              >
                <validation-provider #default="{ errors }" name="Password">
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid' : null"
                  >
                    <b-form-input
                      id="register-password"
                      v-model="form.new_password"
                      class="form-control-merge"
                      :type="passwordFieldType"
                      :state="errors.length > 0 ? false : null"
                      name="register-password"
                      placeholder="············"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        :icon="passwordToggleIcon"
                        class="cursor-pointer"
                        @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                @click="saveChanges()"
              >
                Save changes
              </b-button>
              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                type="reset"
                variant="outline-secondary"
                class="ml-2"
              >
                Reset
              </b-button>
            </b-col>
          </b-row>
        </b-col>
        <!-- phone -->
      </b-row>
    </b-form>
  </div>
</template>

<script>
import Ripple from 'vue-ripple-directive'
import { BRow, BCol, BCard } from 'bootstrap-vue'
import moment from 'moment'
import ProfileHeader from './ProfileHeader.vue'

import Cleave from 'vue-cleave-component'
// eslint-disable-next-line import/no-extraneous-dependencies
import 'cleave.js/dist/addons/cleave-phone.us'
import ProfileAbout from './ProfileAbout.vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import useJwt from '@/auth/jwt/useJwt'

/* eslint-disable global-require */
export default {
  components: {
    ProfileAbout,
    Cleave,
    ValidationProvider,
    ValidationObserver,
    BCard,
    BRow,
    BCol,

    ProfileHeader
  },
  directives: {
    Ripple
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      loading: false,
      profileData: {},
      form: {
        phone: '',
        email: '',
        new_password: null
      },
      userData: JSON.parse(localStorage.getItem('userDataVisitor')) || [],

      options: {
        phone: {
          // phone: true,
          // phoneRegionCode: 'ID',
          numericOnly: true,
          delimiters: [' ', ' ', ' '],
          blocks: [4, 4, 4]
        },
        noKTP: {
          numericOnly: true,
          delimiters: [' ', ' ', ' '],
          blocks: [4, 4, 4, 4],
          uppercase: true
        }
      }
    }
  },
  mounted() {
    // Set initial form values if userData exists
    if (this.userData) {
      this.form.email = null
      this.form.phone = this.userData.phone.slice(2) || null
      this.form.new_password = null
    }
  },
  // watch: {
  //   userData(newUserData) {
  //     // Update form fields if userData changes
  //     this.form.phone = newUserData.phone || null;
  //     this.form.email = newUserData.email || null;
  //   }
  // },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    }
  },
  created() {
    this.$http.get('/profile/data').then((res) => {
      this.profileData = res.data
    })
  },
  methods: {
    moment,
    async saveChanges() {
      // Trim the phone number and remove any spaces
      let phoneNumber = this.form.phone.trim().replace(/\s+/g, '')

      if (phoneNumber.startsWith('0')) {
        // Remove the leading '0' if it exists
        phoneNumber = phoneNumber.slice(1)
      }

      // Now prepend the country code
      const formattedPhone = `62${phoneNumber}`
      console.log('sent to : ', formattedPhone)

      let payload = {
        id: this.userData.id,
        email: this.form.email,
        phone: formattedPhone,
        new_password: this.form.new_password
      }
      this.loading = true
      await useJwt.http
        .put('visitor_user/settings/profile', payload)
        .then(async (result) => {
          this.loading = false
          this.$swal({
            title: 'Save Changes',
            html: `Berhasil Mengubah Data Account`,
            timer: 3000,
            icon: 'success',
            customClass: {
              confirmButton: 'btn btn-primary'
            },
            buttonsStyling: false
          })
        })
        .catch((error) => {
          this.loading = false
          const { message } = error.response.data
          this.$swal({
            title: 'Sorry',
            html: `${message} or Please Try again laters`,
            timer: 3000,
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-primary'
            },
            buttonsStyling: false
          })
        })
    }
  }
}
/* eslint-disable global-require */
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-profile.scss';
</style>
